.modal-offer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .8);
    overflow: hidden;
    /* width: 100vw;
    height: 100vh; */
    z-index: 100500;
    display: flex;
    align-items: center;
}

.offer-carousel {
    width: 100%;
    /* max-height: 100vh;
    padding: 10px 0; */
    display: block;
}

.modal-img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    /* height: calc(100vh - 50px); */
}

.modal-img img {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 50px);
    object-fit: contain;
}
/* 
.ant-carousel .slick-slider,
.ant-carousel .slick-slider .slick-list, 
.ant-carousel .slick-slider .slick-track,
.slick-slide {
    height: 100%;
} */

.slick-dots-bottom {
    bottom: -28px!important;
}

.modal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    color: white;
    cursor: pointer;
    z-index: 1;
}

.modal-close svg {
    width: 100%;
    height: 100%;
}

.modal-close:hover {
    color: #0ca;
}

.ant-carousel {
    width: 80%;
    /* height: 95vh; */
    margin: 10px auto;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: white;
  font-size: unset;
  width: 50px !important;
  height: 50px !important;
  z-index: 1;
  position: absolute;
  top: 50%;
}

.ant-carousel .slick-next {
    right: -80px;
}

.ant-carousel .slick-prev {
    left: -80px;
}

.ant-carousel .slick-prev svg,
.ant-carousel .slick-next svg{
    width: 100%;
    height: 100%;
}


.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: #0ca;
}


.slick-track {
    display: flex !important;
    align-items: center;
}
/* 
.ant-carousel .slick-next {
    color: white;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next::before,
.ant-carousel .slick-prev,
.ant-carousel .slick-prev::before {
    content: '';
} */


@media screen and (max-width: 992px) {
    
    .modal-close {
        width: 30px;
        height: 30px;
    }
    
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        width: 20px !important;
        height: 20px !important;
    }

    .ant-carousel .slick-next {
        inset-inline-end: -35px !important;
    }

    .ant-carousel .slick-prev {
        inset-inline-start: -35px !important;
    }
}

@media screen and (max-width: 750px) {
      
    .ant-carousel .slick-prev,
    .ant-carousel .slick-next {
        display: none !important;
    }

    .ant-carousel {
        width: 100%;
        margin: 0;
    }

    
.modal-close {
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
}

}