.offer-page-container {
    padding: 30px 16px;
    max-width: 1280px;
    margin: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.offer-page__back {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
    background-color: #0ca;
    color: #eef3fa;
    padding: 2px 10px;
    border-radius: 8px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.object {
    /* padding: 16px; */
    border-radius: 6px;
    background: #eef3fa !important;
}

.object-params {
    padding: 16px;
}

.offer-page-title {
    margin: 10px 0;
}

.offer-page-title h1 {
    font-size: 35px;
}

.offer-page-title .price {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.25;
    color: #0ca;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.offer-page-title-location {
    font-size: 20px;
}

.image-gallery {
    display: flex;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    overflow: hidden;
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 80vh;
    
}

.image-gallery:hover {
    opacity: 0.8;
    transition: opacity .5s;
}

.primary-image {
    flex: 1;
}

.primary-image_image {
    height: 100%;
}

.primary-image_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.secondary-image {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 3px;
}


.secondary-image > div {
    width: 300px;
    height: 100%;
    margin-bottom: 3px;
    background-size: cover;
    background-position: center center;
}

.secondary-image > div:nth-last-child(1) {
    margin-bottom: 0;
}

.object-params .param {
    padding-top: 16px;
    padding-bottom: 16px;
}

.object-params .param h3 {
    margin: 0;
    font-size: 28px;
}

.object-params .param div {
    display: flex;
    /* flex-wrap: wrap; */
    font-size: 24px;
}

.object-params .param div div {
    margin-top: 6px;
    column-gap: 10px;
    row-gap: 0;
}

.object-params .description p {
    margin: 0;
}

.readmore {
    cursor: pointer;
    color: #07c;
}

.readmore:hover {
    color: #0ca;
}

.param-add {
    background-image: url('../icons/arrow.svg');
    padding-left: 26px;
    background-repeat: no-repeat;
    background-position: left top 10px;
}

.map-offer-container .leaflet-container {
    height: 600px;
    max-height: 70vh;
    border-radius: 6px;
    overflow: hidden;
    z-index: 1;
}

.map-offer-container h3 {
    font-size: 28px;
    margin-bottom: 10px;
}

.map-offer-container h5 {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

}

.feedback-form-container {
    background: #eef3fa !important;
    padding: 20px;
    /* position: sticky;
    top: 85px; */
}

.react-tel-input .form-control {
    width: 100% !important;
}

.form-text-buttons {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.form-text-buttons button {
    margin-right: 6px;
    height: fit-content !important;
}

.object-contact {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3px;
    max-width: 1280px;
    margin: auto;
}

.object-contact .btn {
    cursor: pointer;
    border-radius: 5px;
    background: #0ca;
    border-top: 3px solid #FFF;
    /* box-shadow: 0 0 0 6px rgb(255 255 255 / 75%); */
    color: #eef3fa;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    align-self: center;
    height: 50px;
    width: 50%;
    justify-content: center;
    font-size: 20px;
}

.object-contact .btn-not {
    background: rgb(239, 133, 161) !important;
}

.object-contact .btn-not:hover {
    background: #f94f5d !important;
}

.object-contact .btn:hover {
    background: rgb(93, 241, 217);
    color: #eef3fa !important;
}

.build-params {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

.build-params_param {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 25%;
}

.build-params_param_text {
    display: flex;
    flex-direction: column;
}

.build-params_param_text p {
    margin: 0;
    font-size: 20px;
}

.build-params_param_text h5 {
    margin: 0;
    font-size: 20px;
    /* max-width: 90%; */
}

.update_date {
    position: absolute;
    right: 50px;
    font-size: 20px;
}

.title-exterior-photo {
    margin-top: 10px;
    margin-left: 10px;
    color: #ffffff;
    font-size: 16px;
    line-height: 18px;
}
@media screen and (max-width: 1400px) {
    .offer-page-container {
        max-width: 1200px;
    }
}

@media screen and (max-width: 1200px) {
    .offer-page-title h1 {
        font-size: 24px;
    }

    .offer-page-title-location {
        font-size: 17px;
    }
  
    .offer-page-title .price {
        font-size: 28px;
    }
}

@media screen and (max-width: 1023px) {
    .secondary-image > div {
        width: 160px;
    }

    .build-params_param {
        width: 33.3%;
    }
}

@media screen and (max-width: 639px) {
    .secondary-image > div {
        width: 120px;
    }

    .build-params_param {
        width: 50%;
    }

    .update_date {
        position: relative;
        right: auto;
    }
}

@media screen and (max-width: 500px) {

    .image-gallery {
        flex-direction: column;
    }

    .primary-image_image img{
        max-height: 400px;
    }
    
    .secondary-image {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-left: 0;
        margin-top: 0;
    }

    .secondary-image > div {
        width: 100%;
        height: 80px;
        margin-bottom: 0;
        margin-right: 3px;
    }

    .secondary-image > div:nth-last-child(1) {
        margin-right: 0;
    }

    .object-params .param h3,
    .map-offer-container h3 {
        font-size: 24px;
    }
    
    .object-params .param div,
    .map-offer-container h5 {
        font-size: 20px;
    }

    .build-params_param {
        width: 100%;
    }

    .build-params_param_text h5 {
        max-width: 100%;
        font-size: 20px;
    }
 
    .build-params_param_text p {
        margin: 0;
        font-size: 20px;
    }

    
    .object-contact .btn {
        font-size: 18px;
    }

    
}


