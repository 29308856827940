/* .ant-layout {
    background: #fff !important;
} */

.wrapper {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    /* position: relative;
    top: 50px; */
    background: #fff !important;
}

.off-scroll {
    overflow: hidden;
}