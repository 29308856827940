.teaser-tile {
    height: 100%;
    font-size: 15px;
    line-height: 1.33;
    color: #606873;
}

.teaser-tile .ant-card-body {
    padding: 0 !important;
}

.teaser-tile .ant-card-cover {
    height: 150px;
}

.teaser-tile .ant-card-body .image-gallery .secondary-image > div {
    width: 200px;
}

.teaser-tile .ant-card-body .image-gallery {
    border-radius: 6px 6px 0 0;
    max-height: 50vh;
}

.teaser-tile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.teaser-tile .desc {
    width: 100%;
    padding: 10px 12px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.citizenship-block {
    display: flex;
    justify-content: end;
}

.citizenship-info {
    display: flex;
    justify-content: end;
}
.information-card {
    display: flex;
    justify-content: space-between;
}
.teaser-tile .ant-divider-horizontal {
    margin: 0;
}

.teaser-tile .desc .title {
    font-size: 18px;
    /* white-space: nowrap; */
    overflow: hidden; 
    color: #003073;
}

.teaser-tile .desc .geo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* margin-bottom: 5px; */
}

.teaser-tile .desc .geo-shortcard {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;
}

.teaser-tile .desc .geo .location {
    white-space: nowrap;
    overflow: hidden;
    color: #98a3b2;
    display: flex;
    align-items: center;
}

.teaser-tile .desc .geo-icon {
    width: 16px;
    height: 16px;
    margin-left: -3px;
}

.teaser-tile .text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #606873;
    margin: 10px 12px;
}

.teaser-tile .desc .units {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.teaser-tile .desc .units .unit-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
}

.teaser-tile .desc .units .unit-item span {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.teaser-tile .desc .citizenship {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.teaser-tile .price {
    font-size: 17px;
    font-weight: 700;
    color: #0ca;
    padding: 10px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.best-offer-block {
    display: flex;
    align-items: center;
    gap: 8px;
}

.best-offer {
    font-size: 14px;
    font-weight: 500;
    color: #4D5163;
}

.best-offer-icon {
    width: 25px;
    height: 25px;
    margin-top: 3px;
}

.teaser-tile .social-actions {
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.teaser-tile .social-actions .social-action {
    display: flex;
    gap: 15px;
    font-size: 24px;
}

.modal-feedback p {
    margin-top: 0;
    text-align: left;
    width: 90%;
}

.modal-feedback textarea{
    width: 100%;
}

.image-gallery-no-photo {
    width: 100%;
    height: 200px;
}

.image-gallery-no-photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.image-count {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    font-size: 16px;
    line-height: 18px;
    flex-direction: row;
    gap: 5px;
    color: #FFF;
    padding: 5px 8px;
    background: rgba(26, 26, 26, 0.7);
    border-radius: 3px;
}

@media screen and (max-width: 576px) {
    .teaser-tile .ant-card-cover {
        height: 250px;
    }

}

@media screen and (max-width: 500px) {
    
    .ant-card-body .image-gallery .primary-image .primary-image_image img {
        max-height: 200px;
        object-fit: cover;
    }
    .best-offer-block {
        margin-top: 8px;
    }
    .information-card {
        display: flex;
        flex-direction: column;
    }
    .citizenship-info {
        display: flex;
        justify-content: left;
    }
    .teaser-tile .price {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        align-items: unset;
    }
}


@media screen and (max-width: 450px) {
    
    .teaser-tile .social-actions .social-action {
        font-size: 20px;
        gap: 5px;
    }
}