footer {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    line-height: 1.33;
    color: #778190;
    background: #eef3fa;
}

.footer__row1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 10px 16px;
    margin-bottom: 20px;
}

.footer__row2 {
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #000;
}

.footer__bank-card img {
    max-width: 60px;
    max-height: 40px;
}

.footer__logo {
    height: 100%;
    max-width: 100px;
    max-height: 100px;
}

.footer__logo img { 
    width: 100%;
    height: 100%;
    object-fit: contain;
}