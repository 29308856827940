.header {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0 10px rgb(0 48 115 / 25%);
}

.header__container {
    display: flex;
    align-items: center;
    flex: 1;
}

.header-logo {
    display: flex;
    align-items: center;

}

.header-logo img {
    width: 90px;
    /*height: 40px;*/
    object-fit: contain;
}

.header-agency-name {
    flex: 1;
    display: flex;
    color: #003073;
    align-items: center;
    font-size: 24px;
    margin-left: 10px;
}

.header-phone {
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
    color: #003073;
    align-items: center;
}

.header-setting {
    position: relative;
    cursor: pointer;
    color: #003073;
    font-size: 18px;
    line-height: 30px;
    padding: 10px 16px;
}

.header-setting__lang:hover {
    color: #0ca;
}

.header-setting__lang-select {
    position: absolute;
    top: 50px;
    right: 0;
    left: auto;
    background: #fff;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 48, 115, .25);
    border-radius: 0 0 6px 6px;
}


@media screen and (max-width: 1200px) {
    .main-menu {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .header-setting__lang {
        font-size: 16px;
    }

    .header a {
        gap: 5px;
    }

    .header-agency-name, .header-phone {
        font-size: 14px;
    }
}
