.main-search-page {
    width: 100%;
    margin: 0 auto;
    padding-left: 92px;
    padding-right: 92px;
}

.main-search-empty-text {
    margin-top: 20px;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
/*.search-block {*/
/*    height: 100vh;*/
/*}*/
.search-button-block {
    border: unset;
    background: unset;
    color: #0ca;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.search-button {
    cursor: pointer;
    background: #0ca;
    margin-top: 20px;
    margin-left: 20px;
    border-radius: 5px;
    width: 64px;
    padding: 5px 10px;
}

.search-input {
    width: 100%;
    max-width: 425px;
    height: 43px;
    margin-top: 16px;
    margin-left: 16px;
    border-radius: 4px;
    border: 1px solid #D9DDE6;
    outline: none;
}

.search-input::placeholder {
    color: #A0A6BF;
    font-size: 16px;
    line-height: 22px;
}
input {
    color: #4D5163;
    padding: 0 10px;
    font-size: 14px;
    line-height: 22px;
}
.search-input-button {
    cursor: pointer;
    background: #0ca;
    border: unset;
    margin-left: 4px;
    border-radius: 5px;
    width: 64px;
    height: 41px;
    padding: 5px 10px;
    color: #ffffff;
}
.search-input-filter {
    cursor: pointer;
    background: unset;
    color: #0ca;
    border: 1px solid #0ca;
    font-size: 14px;
    line-height: 22px;
    width: 64px;
    height: 40px;
    margin-left: 4px;
    border-radius: 4px;
}
.link-search {
    color: #ffffff;
    text-align: center;
}
@media screen and (max-width: 1200px) {
    .main-search-page {
        max-width: 1024px;
        padding-left: 32px;
        padding-right: 32px;
    }
}